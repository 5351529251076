import React from "react";
import { Outlet } from "react-router-dom";
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers'
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./stylesheets/global.scss";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

function App() {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Header />
        <Outlet />
        <Footer />
      </Web3ReactProvider>
    </>
  );
}

export default App;
