import { Container, Typography } from "@mui/material";
import React from "react";
import { useRouteError } from "react-router-dom";

function ErrorPage() {
  const error = useRouteError();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography align="center" variant="h2" mb={1}>
        Oops!
      </Typography>
      <Typography align="center" variant="h5" mb={2}>
        Sorry, an unexpected error has occurred.
      </Typography>
      <Typography align="center" variant="body1" sx={{ opacity: "0.7" }}>
        {error.statusText || error.message}
      </Typography>
    </Container>
  );
}

export default ErrorPage;
