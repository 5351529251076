import {
  Box,
  Container,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "../connectors";
import ButtonGradient from "../components/ButtonGradient";
import bkth from "../images/bk-th.png";
import connect from "../images/connect.png";
import { FormattedMessage } from "react-intl";

const BorderBox = styled(Box)`
  width: 100%;
  height: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
  display: flex;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.2),
    transparent
  );
`;

function ConnectPage() {
  const { active, account, activate } = useWeb3React();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    if (active) {
      navigate("/");
    }
  }, [active]);

  const handleConnectWallet = () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("MetaMask not install");
      return;
    }
    const chainId = ethereum.request({ method: "eth_chainId" });
    if (chainId !== process.env.REACT_APP_CHAIN_ID) {
      ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID }]
      });
    }
    activate(injected, undefined, true)
      .then(async () => {
        // eslint-disable-next-line
        const walletAddress = await injected.getAccount();
        localStorage.setItem("isWalletConnected", "connected");
      })
      .catch(error => {
        if (error instanceof UnsupportedChainIdError) {
          activate(injected);
        } else {
          console.error(error);
        }
      });
  };
  return (
    <Container
      maxWidth="md"
      sx={{
        py: 5,
        px: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <img
        src={bkth}
        alt=""
        style={{
          width: sm ? "400px" : "240px",
          maxWidth: "initial",
          margin: "0 auto 16px auto"
        }}
      />

      <div className="p-relative d-flex flex-column align-center">
        <img
          src={connect}
          alt=""
          style={{ width: sm ? "150%" : "220%", maxWidth: "initial" }}
        />

        <BorderBox
          maxWidth="400px"
          sx={{
            bottom: { xs: "40px", sm: "90px", md: "112px" }
          }}
        >
          <ButtonGradient
            fullWidth
            className="mt-auto"
            size="large"
            onClick={handleConnectWallet}
          >
            <FormattedMessage id="Connect Wallet" />
          </ButtonGradient>
        </BorderBox>
      </div>

      <Typography
        align="center"
        variant={sm ? "h5" : "h6"}
        whiteSpace="pre-line"
        mb={2}
        mt={{ xs: 2, sm: "-48px", md: "-72px", maxWidth: "800px" }}
      >
        <FormattedMessage id="Welcome" />
      </Typography>

      <Typography
        align="center"
        variant={sm ? "body1" : "body2"}
        fontWeight={300}
        mb={3}
        whiteSpace="pre-line"
      >
        <FormattedMessage id="Connect" />
        {`\n`}
        <FormattedMessage id="Connect2" />
      </Typography>
    </Container>
  );
}

export default ConnectPage;
