import { ExpandMoreRounded, Logout } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  MenuItem,
  Select,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "../connectors";
import logo from "../images/logo.png";
import menuIcon from "../images/menu.png";
import menus from "../menus";
import { Context } from "../utility/context/Internationalization";
import RightDrawer from "./RightDrawer";
import Socials from "./Socials";

const CustomAppBar = styled(AppBar)`
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.palette.text.primary};

  &:before {
    content: "";
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Dropdown = styled(Select)`
  color: inherit;
  border: 1px solid ${({ theme }) => theme.palette.white};
  border-radius: 0.5rem;

  fieldset {
    border: none !important;
  }

  svg {
    color: inherit;
  }

  .MuiSelect-select . {
    margin-right: 0.5rem;
  }
`;

const Header = () => {
  const { active, account, activate, deactivate } = useWeb3React();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const context = useContext(Context);
  // eslint-disable-next-line
  const [language, setLanguage] = useState("en");
  const [isShowDrawer, setIsShowDrawer] = React.useState(false);

  const handleConnectWallet = () => {
    const { ethereum } = window;

    if (!ethereum) {
      alert("MetaMask not install");
      return;
    }
    const chainId = ethereum.request({ method: "eth_chainId" });
    if (chainId !== process.env.REACT_APP_CHAIN_ID) {
      ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID }]
      });
    }
    activate(injected, undefined, true)
      .then(async () => {
        // eslint-disable-next-line
        const walletAddress = await injected.getAccount();
        localStorage.setItem("isWalletConnected", "connected");
      })
      .catch(error => {
        if (error instanceof UnsupportedChainIdError) {
          activate(injected);
        } else {
          console.error(error);
        }
      });
  };

  const logout = () => {
    localStorage.setItem("isWalletConnected", "disconnect");
    deactivate();
  };

  const handleChange = event => {
    context.lang.set(event.target.value);
    setLanguage(event.target.value);
  };

  const lang = _.get(context.lang, "get");

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "connected") {
        try {
          await handleConnectWallet();
          localStorage.setItem("isWalletConnected", "connected");
        } catch (ex) {
          localStorage.setItem("isWalletConnected", "disconnect");
        }
      }
    };
    connectWalletOnPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomAppBar position="sticky">
        <Toolbar
          className={`d-flex justify-space-between ${mdUp ? "px-9" : "px-4"}`}
          sx={{
            minHeight: "56px !important",
            px: { xs: "1rem !important", md: "2.5rem !important" }
          }}
        >
          <Box className="d-flex align-center">
            <Link href="https://buakaw.club/en/" rel="noopener noreferrer">
              <img
                src={logo}
                alt="Buakaw 1 NFT"
                height={20}
                className="d-block"
              />
            </Link>

            {lgUp && (
              <Box className="d-flex align-center" sx={{ ml: "5rem" }}>
                {menus.map((m, index) => (
                  <Button
                    key={index}
                    component="a"
                    href={m.path}
                    target="_blank"
                    sx={{ px: "20px", py: 0 }}
                    color="inherit"
                  >
                    {m.name}
                  </Button>
                ))}
              </Box>
            )}
          </Box>

          <Box className="d-flex align-center justify-end">
            {lgUp && <Socials mr={2} />}
            <Context.Consumer>
              {context => {
                return (
                  <Dropdown
                    value={lang}
                    onChange={handleChange}
                    IconComponent={ExpandMoreRounded}
                    size="small"
                    className="pa-0"
                    sx={{
                      minWidth: "initial",
                      "& .MuiSelect-select": { height: "40px !important" }
                    }}
                  >
                    <MenuItem value="en">ENG</MenuItem>
                    <MenuItem value="th">THA</MenuItem>
                    {/* <MenuItem value="ch">中国</MenuItem>
                  <MenuItem value="jp">日本</MenuItem> */}
                  </Dropdown>
                );
              }}
            </Context.Consumer>
            {!lgUp && (
              <IconButton
                onClick={() => {
                  setIsShowDrawer(!isShowDrawer);
                }}
                sx={{ ml: 1 }}
              >
                <img src={menuIcon} alt="" />
              </IconButton>
            )}
            {lgUp && (
              <>
                {!active ? (
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    onClick={handleConnectWallet}
                    className={mdUp ? "ml-3" : "mx-auto"}
                  >
                    <FormattedMessage id="Connect Wallet" />
                  </Button>
                ) : (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      fullWidth
                      className={mdUp ? "ml-3" : "mx-auto"}
                    >
                      {`${account.substring(0, 6)}...${account.substring(
                        account.length - 4
                      )}`}
                    </Button>
                    <IconButton onClick={logout}>
                      <Logout className="ml-1" sx={{ color: "white" }} />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </Box>
        </Toolbar>
      </CustomAppBar>

      <RightDrawer
        open={isShowDrawer && !lgUp}
        onClose={() => {
          setIsShowDrawer(false);
        }}
      />
    </>
  );
};

export default Header;
