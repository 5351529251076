import { Button, styled } from "@mui/material";

const ButtonGradient = styled(Button)`
  background: linear-gradient(269.27deg, #840022 20.91%, #103aa5 147.06%);
  max-width: initial;
  border-radius: 0;
  color: white !important;
  display: block !important;
  transition: 0.2s;
  position: relative;
  padding: 0;
  height: 44px;
  overflow: hidden;

  &:disabled,
  &.Mui-disabled {
    box-shadow: none !important;
  }

  > div {
    position: relative;
    z-index: 1;
    height: 100%;
    line-height: 44px;
    text-align: center;
    transition: 0.3s;
  }

  &:before {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 0.3s;
  }

  &.loading {
    &:before {
      opacity: 1;
      animation-name: progress;
      animation-duration: 30s;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
    }

    div:nth-of-type(01) {
      margin-top: -44px;
    }
  }

  @keyframes progress {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;

export default ButtonGradient;
