import {
  Box,
  Container,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LoadingPage from "../components/LoadingPage";
import ButtonGradient from "../components/ButtonGradient";
import bkth from "../images/bk-th.png";
import fullLogo from "../images/full-logo.png";
import { Context } from "../utility/context/Internationalization";

const CodeBox = styled(Box)`
  background: black;
  padding: 16px;
  width: 560px;
  max-width: 100%;
`;

const FooterBox = styled("footer")`
  background: black;
  width: 100vw;
  display: flex;
  padding: 16px;
  justify-content: center;
  margin-top: auto;
`;

function CodePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const uuid = searchParams.get("uuid");
  const lang = searchParams.get("lang");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [code, setCode] = useState("\u00A0");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const context = useContext(Context);

  useEffect(() => {
    if (!uuid) {
      navigate("/");
    } else {
      const initial = async () => {
        const response = await axios.get(
          process.env.REACT_APP_CODE_API + "?uuid=" + uuid
        );
        if (!response.data?.data?.tk) navigate("/");
        setCode(response.data?.data?.tk);
        setIsLoadingPage(false);
      };
      initial();
    }
  }, [uuid]);

  useEffect(() => {
    if (lang) {
      context.lang.set(lang);
    }
  }, [lang]);

  const onClickDone = useCallback(() => {
    navigate("/");
  }, []);
  return (
    <>
      <Container
        maxWidth="md"
        sx={{ py: 5, px: 3, display: "flex", flexDirection: "column" }}
        className="d-flex flex-column align-center justify-center ma-auto"
      >
        <img
          src={fullLogo}
          alt=""
          style={{ width: sm ? "720px" : "120%", maxWidth: "initial" }}
        />

        <CodeBox mb={5}>
          <Typography align="center" variant="h3" letterSpacing="4px">
            {code}
          </Typography>
        </CodeBox>

        {/* <Box mt={3} mb={8}>
          <Typography align="center" mb={0.5} className="text-shadow">
            Time left
          </Typography>
          <Typography align="center" variant="h5" className="text-shadow">
            29:59
          </Typography>
        </Box> */}

        <ButtonGradient
          fullWidth
          className="d-block mx-auto"
          size="large"
          sx={{ maxWidth: "400px" }}
          onClick={onClickDone}
        >
          <FormattedMessage id="Done" />
        </ButtonGradient>
      </Container>
      <FooterBox>
        <img
          src={bkth}
          alt=""
          style={{
            width: sm ? "400px" : "240px",
            maxWidth: "initial",
            marginBottom: "16px",
          }}
        />
      </FooterBox>

      <LoadingPage isLoading={isLoadingPage} />
    </>
  );
}

export default CodePage;
