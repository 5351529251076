import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import get from "lodash.get";
import { Container } from "@mui/system";
import { Network, Alchemy } from "alchemy-sdk";
import { useNavigate } from "react-router-dom";
import uniq from "lodash.uniq";
import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useWeb3React } from "@web3-react/core";
import ButtonGradient from "../components/ButtonGradient";
import DialogEmail from "../components/DialogEmail";
import LoadingPage from "../components/LoadingPage";
import bkMobile from "../images/bk-mobile.png";
import bkth from "../images/bk-th.png";
import bk from "../images/bk.png";
import btn from "../images/btn-xs.png";

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_KEY,
  network:
    process.env.REACT_APP_CUREENT_ENV === "production"
      ? Network.ETH_MAINNET
      : Network.ETH_GOERLI
};

const alchemy = new Alchemy(settings);

const padFour = num => {
  if (num > 9999) {
    return undefined;
  }
  let numStr = num.toString();
  while (numStr.length < 4) {
    numStr = "0" + numStr;
  }
  return numStr;
};

const BKImg = styled("img")`
  width: 160%;
  margin-bottom: -36%;
  max-width: initial;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: auto;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: -65%;
    height: 90%;
    max-width: initial;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    left: -32%;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    left: -37%;
  }
`;

const findAttribues = (attributes = [], key = "") => {
  const target = attributes.find(x => x.trait_type === key);
  if (target) return target.value;
  return undefined;
};
function FormPage() {
  const { active, account, library } = useWeb3React();
  const [currentAttributes, setCurrentAttributes] = useState([]);
  const [currentSerum, setCurrentSerum] = useState(false);
  const [email, setEmail] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = React.useState(false);
  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [hotel, setHotel] = useState("");
  const [hotels, setHotels] = useState([]);
  const [nft, setNft] = useState();
  const [stringNft, setStringNft] = useState("");
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [ownAssets, setOwnAssets] = useState([]);
  const [ownAssetsString, setOwnAssetsString] = useState([]);
  const intl = useIntl();
  const currentLocale = intl.locale;
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidProvince, setInvalidProvince] = useState(false);
  const [invalidHotel, setInvalidHotel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!active) {
      setOwnAssets([]);
      setOwnAssetsString([]);
      setStringNft("");
      setNft("");
      navigate("/connect");
    }
  }, [active]);

  useEffect(() => {
    if (currentLocale === "th") {
      setProvinces(uniq(hotels.map(x => x["7"])).sort());
    } else {
      setProvinces(uniq(hotels.map(x => x["8"])).sort());
    }
  }, [currentLocale, hotels]);

  useEffect(() => {
    if (account) {
      const initial = async () => {
        setIsLoadingPage(true);
        const nfts = await alchemy.nft.getNftsForOwner(account);
        const hotelResponse = await axios.get(process.env.REACT_APP_HOTEL_API);
        const hotelData = hotelResponse.data.data.data.Items;
        const ownedNfts = nfts.ownedNfts;
        const buakawNfts = ownedNfts.filter(
          x =>
            x.contract?.address ===
            (process.env.REACT_APP_EVO_ADDRESS || "").toLowerCase()
        );
        setOwnAssets(buakawNfts);
        setOwnAssetsString(buakawNfts.map(x => x.tokenId));
        if (buakawNfts.length > 0) {
          const metaDataURL = buakawNfts[0].tokenUri?.gateway;
          const metaDataResponse = await axios.get(metaDataURL);
          const attributes = metaDataResponse.data?.attributes || [];
          setCurrentAttributes(attributes);
          const isAppliedSerum = findAttribues(attributes, "Serum Applied");
          const serum = isAppliedSerum === "None" ? false : true;
          if (isAppliedSerum === "None") {
            setCurrentSerum(false);
          } else {
            setCurrentSerum(true);
          }
          const filteredUsedHotel = hotelData.filter(b => {
            const thisHotel = findAttribues(attributes, b["6"]);
            if (serum) return true;
            if (!serum && thisHotel > 0) return false;
            return true;
          });

          setHotels(filteredUsedHotel);
          setProvinces(
            uniq(
              filteredUsedHotel.map(x =>
                currentLocale === "th" ? x["7"] : x["8"]
              )
            ).sort()
          );
          setNft(buakawNfts[0]);
          setStringNft(buakawNfts[0].tokenId);
        } else {
          setHotels(hotelData);
          setProvinces(
            uniq(
              hotelData.map(x => (currentLocale === "th" ? x["7"] : x["8"]))
            ).sort()
          );
        }
        setIsLoadingPage(false);
      };
      initial();
    }
  }, [account]);

  const handleChangeProvince = e => {
    setInvalidProvince(false);
    setProvince(e.target.value);
    setHotel(undefined);
  };

  const handleChangeHotel = e => {
    setInvalidHotel(false);
    setHotel(e.target.value);
  };

  const handleChangeNFT = async e => {
    setIsLoadingPage(true);
    setStringNft(e.target.value);
    const targetNft = ownAssets.find(x => x.tokenId === e.target.value);
    if (targetNft) {
      setNft(targetNft);
      const metaDataURL = targetNft.tokenUri?.gateway;
      const metaDataResponse = await axios.get(metaDataURL);
      const attributes = metaDataResponse.data?.attributes || [];
      setCurrentAttributes(attributes);
      const isAppliedSerum = findAttribues(attributes, "Serum Applied");
      const serum = isAppliedSerum === "None" ? false : true;
      if (isAppliedSerum === "None") {
        setCurrentSerum(false);
      } else {
        setCurrentSerum(true);
      }
      const filteredUsedHotel = hotels.filter(b => {
        const thisHotel = findAttribues(attributes, b["6"]);
        if (serum) return true;
        if (!serum && thisHotel > 0) return false;
        return true;
      });

      setHotels(filteredUsedHotel);
      setProvinces(
        uniq(
          filteredUsedHotel.map(x => (currentLocale === "th" ? x["7"] : x["8"]))
        ).sort()
      );
    }
    setIsLoadingPage(false);
  };

  const filteredHotel = useMemo(() => {
    return hotels.filter(
      x => (currentLocale === "th" ? x["7"] : x["8"]) === province
    );
  }, [province, hotels]);

  const validateForm = () => {
    const isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    let isError = false;

    if (!email || !email.match(isValidEmail)) {
      setInvalidEmail(true);
      isError = true;
    }

    if (!province) {
      setInvalidProvince(true);
      isError = true;
    }

    if (!hotel) {
      setInvalidHotel(true);
      isError = true;
    }

    return isError;
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      setIsLoadingButton(true);
      let currentHotel = hotels.find(
        x => (currentLocale === "th" ? x["5"] : x["6"]) === hotel
      );
      if (!currentHotel) {
        currentHotel = hotels.find(
          x => (currentLocale === "th" ? x["6"] : x["5"]) === hotel
        );
      }
      if (currentHotel) {
        const response = await axios.post(process.env.REACT_APP_SEND_CODE_API, {
          lang: currentLocale,
          hotelName: hotel,
          hotelCode: currentHotel["0"],
          email
        });
        const newCode = response.data?.data?.code;
        const discountData = {
          tokenId: nft.tokenId,
          hotelId: currentHotel.id,
          hotelCode: newCode,
          expire: 1704067199999
        };
        if (!active) throw "Error not active account";
        const signature = await library
          .getSigner()
          .signMessage(JSON.stringify(discountData));
        const discountResponse = await axios.post(
          process.env.REACT_APP_DISCOUNT_API,
          {
            data: discountData,
            signature: signature
          }
        );
        setShowDialog(true);
      }
      setIsLoadingButton(false);
    }
  };
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{
          py: 5,
          px: 3,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <BKImg src={md ? bk : bkMobile} alt="" />

        <Box
          className={`d-flex flex-column justify-center align-center ml-auto ${
            md ? "col-8" : "col-12"
          }`}
          position="relative"
          zIndex={1}
        >
          <Box maxWidth="680px">
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{ minHeight: "33px" }}
            >
              <FormattedMessage id="dear" />
            </Typography>
            <Typography
              variant="h2"
              fontWeight={700}
              className="text-shadow"
              pr={2}
            >
              NFT Holder
              {!sm &&
                `  ${
                  stringNft ? `#${padFour(stringNft)}` : "\u00A0".repeat(11)
                }`}
            </Typography>
            <div className={`d-flex align-center ${!sm ? "flex-wrap" : ""}`}>
              {sm && (
                <Typography
                  variant="h1"
                  fontWeight={700}
                  className="text-shadow"
                  lineHeight={1}
                  mr={3}
                >
                  {`${
                    stringNft ? `#${padFour(stringNft)}` : "\u00A0".repeat(11)
                  }`}
                </Typography>
              )}

              <Box mb={{ xs: 3, sm: 0 }} display="inline">
                <Typography
                  variant="h5"
                  display="inline"
                  fontWeight={300}
                  mr={1}
                >
                  <FormattedMessage id="You have been confirmed to receive" />
                </Typography>
                <Typography
                  variant="h4"
                  display="inline"
                  fontWeight={700}
                  mr={1}
                >
                  <FormattedMessage id="special privileges" />
                </Typography>
                <Typography
                  variant="h5"
                  display={sm ? "block" : "inline"}
                  mb={{ xs: 3, sm: 0 }}
                  fontWeight={300}
                  mr={1}
                >
                  <FormattedMessage id="from joining activity." />
                </Typography>

                {!sm && (
                  <img
                    src={bkth}
                    alt=""
                    style={{
                      width: "200px",
                      display: "inline"
                    }}
                  />
                )}
              </Box>
            </div>
          </Box>

          {sm && (
            <img
              src={bkth}
              alt=""
              style={{
                width: "400px",
                maxWidth: "initial",
                margin: "16px auto"
              }}
            />
          )}

          <Box
            sx={{ border: "2px solid #FFFFFF" }}
            p={2}
            width="640px"
            maxWidth="100%"
          >
            <Typography
              align="center"
              variant={sm ? "h6" : "body1"}
              className="text-shadow"
              whiteSpace="pre-line"
            >
              <FormattedMessage id="Please register" />
            </Typography>
          </Box>

          <Box
            className="d-flex justify-center align-end flex-wrap"
            mt={4}
            mb={6}
            width="640px"
            maxWidth="100%"
          >
            <div className={`flex-shrink mb-3 ${sm ? "col-6 pr-2" : "col-12"}`}>
              <Typography variant="caption" mb={1} className="d-block">
                <FormattedMessage id="Choose your NFT" />
              </Typography>
              <Select
                disabled={!active}
                fullWidth
                value={stringNft}
                onChange={handleChangeNFT}
                input={<OutlinedInput />}
              >
                {ownAssetsString.map(x => {
                  return (
                    <MenuItem key={x} value={x}>
                      {padFour(x)}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className={`flex-shrink mb-3 ${sm ? "col-6 pl-2" : "col-12"}`}>
              <Typography variant="caption" mb={1} className="d-block">
                <FormattedMessage id="Register Email" />
              </Typography>
              <TextField
                error={invalidEmail}
                helperText={
                  invalidEmail && (
                    <FormattedMessage id="Please enter a valid email address" />
                  )
                }
                fullWidth
                onChange={e => {
                  setInvalidEmail(false);
                  setEmail(e.target.value);
                }}
                value={email}
                placeholder="abc@buakaw.th"
                disabled={!active}
              />
            </div>
            <div className={`flex-shrink ${sm ? "col-6 pr-2" : "col-12 mb-3"}`}>
              <Typography variant="caption" mb={1} className="d-block">
                <FormattedMessage id="Choose your hotel and province to check-in" />
              </Typography>
              <FormControl error={invalidProvince} fullWidth>
                <Select
                  disabled={!active}
                  fullWidth
                  displayEmpty
                  value={province}
                  onChange={handleChangeProvince}
                  input={<OutlinedInput />}
                  renderValue={selected => {
                    if ((selected || "").length === 0) {
                      return (
                        <Typography sx={{ opacity: "0.5" }}>
                          <FormattedMessage id="Choose a province" />
                        </Typography>
                      );
                    }

                    return selected;
                  }}
                >
                  {provinces.map(x => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
                {invalidProvince && (
                  <FormHelperText>
                    <FormattedMessage id="Please choose a province" />
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={`flex-shrink ${sm ? "col-6 pl-2" : "col-12"}`}>
              <FormControl error={invalidHotel} fullWidth>
                <Select
                  fullWidth
                  displayEmpty
                  value={hotel}
                  onChange={handleChangeHotel}
                  input={<OutlinedInput />}
                  className="col-6"
                  disabled={!province || !active}
                  renderValue={selected => {
                    if (!hotel || (selected || "").length === 0) {
                      return (
                        <Typography sx={{ opacity: "0.5" }}>
                          <FormattedMessage id="Choose a hotel" />
                        </Typography>
                      );
                    }

                    return selected;
                  }}
                >
                  {filteredHotel.map(x => (
                    <MenuItem value={currentLocale === "th" ? x["5"] : x["6"]}>
                      {currentLocale === "th" ? x["5"] : x["6"]}
                    </MenuItem>
                  ))}
                </Select>
                {invalidHotel && (
                  <FormHelperText>
                    <FormattedMessage id="Please choose a hotel" />
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </Box>

          <ButtonGradient
            disableRipple
            fullWidth
            className={`d-block ${isLoadingButton ? "loading" : ""}`}
            size="large"
            sx={{
              maxWidth: "400px",
              mx: "auto"
            }}
            onClick={onSubmit}
            disabled={isLoadingButton || !active}
          >
            <div>
              <FormattedMessage id="Submit" />
            </div>
            <div className="d-flex align-center justify-center">
              <CircularProgress
                size={16}
                className="d-block mr-3"
                color="inherit"
              />
              <FormattedMessage id="Loading" />
            </div>
          </ButtonGradient>

          <Typography
            variant="caption"
            display="inline-flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="center"
            alignItems="center"
            align="center"
            mt={4}
            sx={{ opacity: "0.7" }}
          >
            <FormattedMessage id="You can view" />
            <Link
              href="https://nftbuakawxamazingthailand.buakaw.club/"
              target="_blank"
              rel="noreferrer"
              m={{ xs: "6px auto 0 auto", sm: "0 0 0 6px" }}
            >
              <img src={btn} width="70px" alt="" />
            </Link>
          </Typography>
        </Box>
      </Container>

      <DialogEmail
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
      />

      <LoadingPage isLoading={isLoadingPage} />
    </>
  );
}

export default FormPage;
