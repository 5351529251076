import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
  typography: {
    fontFamily: '"Noto Sans","Noto Sans Thai", sans-serif',

    h3: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      main: "#D7A137",
    },
    text: {
      primary: "#fff",
      secondary: "#9C9C9C",
      disabled: "rgba(255,255,255,0.5)",
    },
    action: {
      disabled: "rgba(255,255,255,0.5)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          maxWidth: "200px",
          color: "white",
        },
        contained: {
          "&.MuiButton-fullWidth": {
            // background: "#FFFFFF",
            borderRadius: "24px",
            color: "initial",
            padding: "4px 16px",
          },
        },
        text: {
          "&:hover": {
            color: "#E09F00",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "white",
          "& svg": { color: "white" },

          ":hover": {
            "&:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D7A137",
            },
          },

          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
        },
        notchedOutline: {
          background: "rgba(255, 255, 255, 0.2)",
          border: "2px solid #FFFFFF",
          boxShadow: "inset 0px 0px 24px rgba(0, 0, 0, 0.55)",
          borderRadius: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          filter: "drop-shadow(0px 0px 4px #FFFFFF)",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.55) 17.19%, rgba(215, 161, 55, 0.4125) 100%)",
          backdropFilter: "blur(5px)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "initial",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Noto Sans","Noto Sans Thai", sans-serif',
          lineHeight: "1.4",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: '"Noto Sans","Noto Sans Thai", sans-serif',
          minWidth: "200px",
        },
        input: {
          height: "48px !important",
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#e09f00",
          width: "300px",
          maxWidth: "100%",
          padding: "120px 3rem 5rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          bottom: "-24px",
          left: 0,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
