import { Dialog, styled, Typography } from "@mui/material";
import React from "react";
import bg from "../images/bg-dialog.png";
import ButtonGradient from "./ButtonGradient";
import { FormattedMessage } from "react-intl";

const DialogStyle = styled(Dialog)`
  .MuiPaper-root {
    background: url(${bg});
    background-size: cover;
    background-position: right bottom;
    background-repeat: no-repeat;
    max-width: 360px;
    padding: 80px 40px;
    border-radius: 12px;
  }
`;

const DialogEmail = ({ open, onClose }) => {
  return (
    <DialogStyle fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Typography align="center" mb={0.5} variant="h5">
        <FormattedMessage id="Email sent" />
      </Typography>
      <Typography align="center" mb={3} fontWeight={300}>
        <FormattedMessage id="Please check" />
      </Typography>
      <Typography
        variant="body2"
        align="center"
        mb={3.5}
        fontWeight={300}
        whiteSpace="pre-line"
      >
        <FormattedMessage id="If you do not see the email, check your “junk mail” folder or “spam” folder." />
      </Typography>
      <ButtonGradient
        fullWidth
        className="d-block mx-auto"
        size="large"
        sx={{ maxWidth: "160px" }}
        onClick={onClose}
      >
        <FormattedMessage id="Ok" />
      </ButtonGradient>
    </DialogStyle>
  );
};

export default DialogEmail;
